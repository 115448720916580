import React from 'react';
import GetStarted from '../components/GetStarted';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import '../styles/about.scss';
import PeopleGrid from '../components/PeopleGrid/PeopleGrid';
import { featuredLogo, leaders, stats } from '../content/about';
import CountUp from '../components/CountUp/CountUp';

const AboutPage = (props) => {
  return (
    <Layout
      lightHeader
      {...props}
      signInLink="https://merchant.cashfree.com/merchants/login?source-action=About%20Us&action=Sign%20In&button-id=SignIn_Navbar"
      createAccountLink="https://merchant.cashfree.com/merchants/signup?source-action=About%20Us&action=Sign%20Up&button-id=StartNow_Navbar">
      <SEO
        title="About | Cashfree Payments "
        url="https://cashfree.com/about-us/"
      />

      <div className="section-padding dark-hero about-hero">
        <div className="container position-relative">
          <div className="flex flex-wrap justify-start lg:justify-between pb-4 md:pb-0">
            <div className="content w-full lg:w-7/12 md:pr-10 lg:mb-24">
              <h2 className="text-sm font-semibold text-white pl-5 mb-[10px] relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] uppercase font-body">
                About Cashfree
              </h2>
              <div className="mb-8 md:mb-10 text-xl md:text-2xl font-semibold max-w-[680px] lg:w-[110%] text-white font-heading">
                Powering businesses of today with the payments infrastructure of
                tomorrow
              </div>
              <div className="text-cf-cold-purple text-2md md:text-2.5md">
                <p className="mb-4">
                  Cashfree Payments is India’s leading payments and API banking
                  company. It provides full-stack payment solutions for
                  businesses in India to accept payments and make payouts via{' '}
                  <span className="text-white font-medium">120+ payment</span>{' '}
                  modes - all with a simple integration.
                </p>
                <p className="mb-4">
                  Over{' '}
                  <span className="text-white font-medium">
                    3,00,000 businesses
                  </span>{' '}
                  trust Cashfree Payments with payment collections, vendor
                  payouts, salary payouts, bulk refunds, expense reimbursements,
                  loyalty and rewards, and so much more.
                </p>
                <p className="mb-4">
                  We enable businesses of all we are building the payments
                  infrastructure of tomorrow, today.
                </p>
                <p className="mb-8 md:mb-4">
                  We entered the payments industry in 2015 to facilitate
                  Cash-on-Delivery payments for ecommerce businesses. We are
                  also contributing to the pace of digitisation of India by
                  working closely with traditional banks to increase ease and
                  convenience for last-mile customers.
                </p>
              </div>
            </div>
            <div className="w-full lg:w-5/12 map-container">
              <div className="sticky top-4">
                <div className="img-container">
                  <img
                    src="/img/about/map.png"
                    width="524"
                    height="592"
                    alt=""
                    className="w-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="bg-white stats-container overflow-hidden relative">
        <div className="container relative z-[1]">
          <div className="flex lg:justify-between flex-wrap -mx-4 p-section-2  border-b-cf-stroke border-b border-dashed">
            {stats.map((s) => (
              <div
                className="md:text-center pb-7 lg:pb-0  last:pb-0 px-4 last:w-full w-1/2 md:w-max md:last:w-max"
                key={s.id}>
                <div className="text-[32px] md:text-[42px] leading-[40px] md:leading-[48px] text-cf-primary font-semibold">
                  <CountUp {...s.countUpProps} duration={1.4} ease="circ.out" />
                </div>
                <div className="text-base">{s.text}</div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="relative p-section-2 bg-white">
        <div className="container">
          <div className="flex flex-wrap flex-col-reverse md:flex-row">
            <div className="w-full md:w-7/12 md:pr-[19px]">
              <picture>
                <source
                  srcSet="/img/about/money-move.svg"
                  width="717"
                  height="441"
                  media="(min-width:768px)"
                />
                <img
                  src="/img/about/money-move-small.svg"
                  width="343"
                  height="282"
                  alt=""
                  className="w-full"
                />
              </picture>
            </div>
            <div className="w-full md:w-5/12 md:self-center md:pl-[19px]">
              <p className="text-sm md:hidden font-semibold text-cf-primary pl-5 mb-2 uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px]">
                About cashfree
              </p>
              <div className="text-shs md:text-shl font-heading font-semibold mb-3 md:mb-4">
                Move money with&nbsp;ease
              </div>
              <div className="text-md">
                <p>
                  At the heart of Cashfree Payments, lies its dynamic payments
                  stack, which makes processing transactions quick, efficient,
                  and reliable.
                </p>
                <br />
                <p className="mb-7 md:mb-0">
                  Whether you’re accepting payments or making payouts, no matter
                  what your payment flow is, our comprehensive product suite
                  will take care of it.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="p-section-2 relative bg-cf-hero overflow-hidden about-featured">
        <div className="container text-white relative z-[1]">
          <div className="text-shs md:text-shl text-center font-heading font-semibold mb-7">
            Featured
          </div>
          <div className="flex flex-wrap justify-items-stretch mx-[-7.5px] md:mx-[-19px]">
            {featuredLogo.map((fl) => (
              <div
                key={fl.id}
                className="px-[7.5px] md:px-[19px] pt-[15px] md:pt-[32px] w-1/2 md:w-1/3">
                <div className="bg-[#d9d9d924] p-4 md:py-[20px] flex justify-center items-center h-full min-h-[90px] md:min-h-[100px] rounded">
                  <img
                    className={`max-w-[80%] md:max-w-${fl.maxWidth}`}
                    src={`/img/about/${fl.src}`}
                    alt={fl.alt}
                    width={fl.width}
                    height={fl.height}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="relative p-section-3">
        <div className="container">
          <div className="flex flex-wrap flex-col-reverse md:flex-row">
            <div className="md:w-7/12 md:pr-[19px]">
              <div className="w-full bg-cf-light-grey logo-lane-container">
                <div className="lane" />
                <div className="lane" />
                <div className="lane" />
              </div>
            </div>
            <div className="md:w-5/12 md:self-center md:pl-[19px]">
              <div className="text-shs md:text-shl font-heading font-semibold mb-4">
                From startups to large enterprises
              </div>
              <p className="text-md md:max-w-[400px] mb-7 mb:mb-0">
                Cashfree Payments is for businesses of all shapes and sizes. We
                grow, as you grow. And, we’re trusted by 1000s of businesses
                just like you!
              </p>
            </div>
          </div>
          <div className="flex flex-wrap mt-[60px] md:mt-[160px] flex-col md:flex-row">
            <div className="md:w-5/12 md:self-center md:pr-[19px]">
              <div className="text-shs md:text-shl font-heading font-semibold mb-3 md:mb-4">
                Focusing on what matters
              </div>
              <div className="text-md md:max-w-[400px]">
                <p className="mb-4">
                  We take away the hassles of money movement from your equation,
                  so you focus on what matters most - your business.
                </p>
                <p className="mb-8">
                  With an ear to the ground, we constantly evolve and innovate
                  to optimise end-to-end payments for you.
                </p>
                <a
                  className="button button-green inline-block mb-7 md:mb-0 w-full md:w-auto"
                  href="https://www.shopify.com/admin/settings/payments/alternative-providers/1058691">
                  Work with us <span className="chevron" />
                </a>
              </div>
            </div>
            <div className="md:w-7/12 md:pl-[19px]">
              <picture>
                <source
                  srcSet="/img/about/money.svg"
                  width="718"
                  height="441"
                  media="(min-width:768px)"
                />
                <img
                  src="/img/about/money-small.svg"
                  width="343"
                  height="282"
                  alt=""
                  className="w-full"
                />
              </picture>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-cf-light-grey p-section-2 about-leadership">
        <div className="container">
          <div className="font-heading font-bold text-shs md:text-shl text-center">
            Leadership
          </div>
          <div className="mt-8 md:mt-5 text-2.5sm md:text-base text-center">
            Meet the leaders driving payments to the future
          </div>
          <PeopleGrid
            data={leaders.map((l) => ({
              ...l,
              image: {
                ...l.image,
                alt: l.name,
                src: `/img/about/${l.image.src}`,
              },
            }))}
          />
        </div>
      </section>
      <section className="relative py-[48px] md:py-[80px] text-center">
        <div className="container">
          <div className="font-heading font-bold text-shs md:text-shl">
            Keep in touch
          </div>
          <p className="text-2sm md:text-base mt-3 md:mt-4 mb-8 md:mb-10">
            Get the latest in product, industry trends, guides, and more - right
            to your inbox.
          </p>
          <form>
            <div className="flex w-full lg:max-w-[384px] mx-auto">
              <input
                type="email"
                placeholder="Enter email address"
                className="bg-cf-light flex-1 pl-3 rounded rounded-r-none w-full text-vtiny md:text-md"
              />
              <button
                type="button"
                className="button button-green small left-radius-none bg-cf-green">
                Subscribe <span className="chevron md:hidden" />
              </button>
            </div>
          </form>
        </div>
      </section>
      <GetStarted
        startNowLink="https://merchant.cashfree.com/merchants/signup?source-action=About%20Us&action=Sign%20Up&button-id=StartNow_Footer"
        getInTouchLink="https://www.cashfree.com/contact-sales?source-action=About%20Us&action=Contact%20Sales&button-id=ContactSales_Footer"
      />
    </Layout>
  );
};

export default AboutPage;
