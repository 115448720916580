import React from 'react';
import PropTypes from 'prop-types';
// import './PeopleGrid.scss';

function PeopleGrid({ data }) {
  return (
    <div className="flex flex-wrap mx-[-11.5px] md:mx-[-19px] justify-center">
      {data.map((d) => (
        <div
          className="px-[11.5px] md:px-[19px] w-1/2 md:w-1/3 lg:w-1/4 pt-8 md:pt-16 flex flex-col group text-center"
          key={d.id}>
          <div>
            <div className="relative flex justify-center overflow-hidden">
              <img
                loading="lazy"
                className="object-contain object-bottom z-[1] w-full max-w-[220px]"
                alt=""
                {...d.image}
              />
            </div>
            <div className="font-bold text-2sm md:text-2.5md text-black mt-[6px] md:mt-4">
              {d.name}
            </div>
            <div className="font-medium text-2sm md:leading-7 opacity-60 text-black m-0">
              {d.designation}
            </div>
          </div>
          <div className="flex mt-3md items-center justify-center">
            {d.linkedIn && (
              <a href={d.linkedIn} target="_blank" rel="noopener noreferrer">
                <div className="w-[20px] md:w-[24px]">
                  <img src="/img/linkedin-icon.svg" alt="Linkedin" />
                </div>
              </a>
            )}
            {d.twitter && (
              <a
                href={d.linkedIn}
                className="ml-3"
                target="_blank"
                rel="noopener noreferrer">
                <div className="w-[20px] md:w-[24px]">
                  <img src="/img/twitter-icon.svg" alt="Twitter" />
                </div>
              </a>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
PeopleGrid.defaultProps = {
  data: [],
};
PeopleGrid.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      image: PropTypes.shape({}),
      name: PropTypes.string,
      designation: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      linkedIn: PropTypes.string,
      twitter: PropTypes.string,
    }),
  ),
};
export default PeopleGrid;
