import React from 'react';

export const featuredLogo = [
  { id: 0, src: 'ycombinator.png', width: '181', height: '37', maxWidth: 181 },
  { id: 1, src: 'krasia.png', width: '128', height: '36', maxWidth: 128 },
  { id: 2, src: 'inc42.png', width: '120', height: '47', maxWidth: 120 },
  {
    id: 3,
    src: 'bwbusinessworld.png',
    width: '181',
    height: '22s',
    maxWidth: 181,
  },
  { id: 4, src: 'deloitte.png', width: '134', height: '45', maxWidth: 134 },
  { id: 5, src: 'cnbctv.png', width: '58', height: '52', maxWidth: 58 },
];
export const leaders = [
  {
    id: 0,
    name: 'Reeju Datta',
    designation: 'Co-Founder',
    linkedIn: 'https://www.linkedin.com/in/reejudatta/',
    image: {
      src: 'reeju-datta.png',
      width: '331',
      height: '331',
    },
  },
  {
    id: 1,
    name: 'Akash Sinha',
    designation: 'CEO/ Co-Founder',
    linkedIn: 'https://www.linkedin.com/in/sinhaakash/',
    image: {
      src: 'akash-sinha.png',
      width: '331',
      height: '331',
    },
  },
  {
    id: 2,
    name: 'Vikas Guru',
    designation: 'Chief Financial Officer',
    linkedIn: 'https://www.linkedin.com/in/vikas-guru-42745b13/',
    image: {
      src: 'vikas-guru.png',
      width: '331',
      height: '331',
    },
  },
  {
    id: 3,
    name: 'Arun Tikoo',
    designation: <>SVP - Growth &&nbsp;Strategy</>,
    linkedIn: 'https://www.linkedin.com/in/arun-tikoo-1597ba11/',
    image: {
      src: 'arun-tikoo.png',
      width: '331',
      height: '331',
    },
  },
  {
    id: 4,
    name: 'Ramkumar M Venkatesan',
    designation: 'Senior Vice President - Engineering',
    linkedIn: 'https://www.linkedin.com/in/ramkumar-venkatesan/',
    image: {
      src: 'ramkumar-m.png',
      width: '331',
      height: '331',
    },
  },
  {
    id: 5,
    name: 'Sapna Sukumar',
    designation: 'Vice President - HR',
    linkedIn: 'https://www.linkedin.com/in/sapnasukumar/',
    image: {
      src: 'sapna-sukumar.png',
      width: '331',
      height: '331',
    },
  },
  {
    id: 6,
    name: 'Anil Kumar V',
    designation: 'Vice President - Growth & Strategy',
    linkedIn: 'https://www.linkedin.com/in/anilkvn/',
    image: {
      src: 'anil-kumar.png',
      width: '331',
      height: '331',
    },
  },
  {
    id: 7,
    name: 'Nikhil G Agrawal',
    designation: 'Vice President - Investor Relations',
    linkedIn: 'https://in.linkedin.com/in/nikagr',
    image: {
      src: 'nikhil-agrawal.png',
      width: '331',
      height: '331',
    },
  },
  {
    id: 8,
    name: 'Vinit Barve',
    designation: 'Vice President - New Initiatives',
    linkedIn: 'https://www.linkedin.com/in/vinit-barve-0aa0379/',
    image: {
      src: 'vinit-barve.png',
      width: '331',
      height: '331',
    },
  },
  {
    id: 9,
    name: 'Punit Pawan',
    designation: 'AVP - Growth & Strategy',
    linkedIn: 'https://www.linkedin.com/in/punitpawan/',
    image: {
      src: 'punit-pawan.png',
      width: '331',
      height: '331',
    },
  },
  {
    id: 10,
    name: 'Sagar Agarwal',
    designation: 'Senior Director - Product Management',
    linkedIn: 'https://www.linkedin.com/in/sagarwal08/',
    image: {
      src: 'sagar-agarwal.png',
      width: '331',
      height: '331',
    },
  },
  {
    id: 11,
    name: 'Fasih Abbas Mirza',
    designation: 'Senior Director - Customer Success',
    linkedIn: 'https://www.linkedin.com/in/fasih-abbas-m-4b96b2105/',
    image: {
      src: 'fasih-abbas.png',
      width: '331',
      height: '331',
    },
  },
  {
    id: 12,
    name: 'Swagata Gupta',
    designation: <>Sr Dir - Corp Comm & Public&nbsp;Affairs</>,
    linkedIn: 'https://www.linkedin.com/in/swagata-gupta-1209ab11/',
    image: {
      src: 'swagata-gupta.png',
      width: '331',
      height: '331',
    },
  },
  {
    id: 13,
    name: 'Vaibhav Mehrotra',
    designation: 'Senior Director - Marketing',
    linkedIn: 'https://www.linkedin.com/in/vaibhavmehrotra/',
    image: {
      src: 'vaibhav-mehrotra.png',
      width: '331',
      height: '331',
    },
  },
  {
    id: 14,
    name: 'N Pugazhenthy',
    designation: 'Senior Director - Operations',
    linkedIn: 'https://www.linkedin.com/in/pugazhenthy-n-8131bb12/',
    image: {
      src: 'pugazhenthy.png',
      width: '331',
      height: '331',
    },
  },
  {
    id: 15,
    name: 'Tushar Tarun',
    designation: 'Senior Director - Legal',
    linkedIn: 'https://www.linkedin.com/in/tushartarun/',
    image: {
      src: 'tushar-tarun.png',
      width: '331',
      height: '331',
    },
  },
];
export const stats = [
  {
    id: 0,
    countUpProps: {
      value: 2015,
      animate: true,
    },
    text: 'Launched year',
  },
  {
    id: 1,
    countUpProps: {
      value: 2018,
      animate: true,
    },
    text: 'Profitable year',
  },
  {
    id: 2,
    countUpProps: {
      value: 300000,
      startValue: 150000,
      suffix: '+',
      useIndianFormat: true,
    },
    text: 'Businesses served',
  },
  {
    id: 3,
    countUpProps: {
      value: 40,
      prefix: '$',
      suffix: ' Bn+',
    },
    text: 'Processed annually',
  },
  {
    id: 4,
    countUpProps: {
      value: 200,
      suffix: ' M+',
    },
    text: 'Bank accounts served',
  },
];
